import React, { ChangeEvent, useEffect, useState  } from 'react';
import '../styles/index.css';
import logo from '../images/logo.png';
import arCurrency from '../images/ar-currency-1.png';
import arCurrency1 from '../images/ar-currency.png';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Helmet } from "react-helmet"

function Index() {  

  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [msgInput, setMsgEmailInput] = useState("");
  const [submitText, setSubmitText] = useState("SUBMIT");

  

  useEffect(() => {

  }, []);

  const submitContact = () => {        
    if(!nameInput && !emailInput && !msgInput) {
      alert('Some fields are required.')
      return;
    }  
    if(submitText != "SUBMIT") return;
    setSubmitText("LOADING....");            

    fetch('https://api.mailgun.net/v3/sandbox9eddb104ecdb494abf31eaf55f8c247c.mailgun.org/messages', {
      method: 'post',
      headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Authorization": "Basic " + btoa('api:3fd3055e24700d33a53d22b10596b287-db4df449-3eb2580e')
      },
      body: `from=Contact Robot <contact@sandbox9eddb104ecdb494abf31eaf55f8c247c.mailgun.org>&to=monynith@gmail.com&subject=contact from website&text=Name:${nameInput}, Email: ${emailInput}, Message: ${msgInput}`
    })    
    .then(function (data) {
      // console.log('Request succeeded with JSON response', data);
      setSubmitText('SUBMITTED');
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });

  }

  const handleChangeName = (e) => {
    setNameInput(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmailInput(e.target.value);
  };

  const handleChangeMsg = (e) => {
    setMsgEmailInput(e.target.value);
  };
  

  return (
    <main className='container'>
      <Helmet>        
        <title>N-O App</title>
        <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div id="contact-us-bottom">
        <AnchorLink to="#contact" className="custom-a" title="contact">
          <span className="navigate">contact us</span>
        </AnchorLink>
      </div>      
      <div className='wrapper'>
        <div id="header">
          <img id="logo" src={logo} alt="logo" />
          <div id="menu">
            <AnchorLink to="#contact" className="custom-a"><span className="navigate" id="contact-us">contact us</span></AnchorLink>
            <a className="custom-a" href="https://www.n-o.me/api/privacy/base" target="_blank" id="privacy"><span className="navigate">privacy</span></a>
          </div>
        </div>       
        <div id="content">
          <p className="title">welcome to <b>n-o app</b></p>          
          {/* <p className="subtitle">simple apps, simplify life.</p> */}
          <p className="caption">no app!<b> just download</b></p>
          <a href='https://link.n-o.me/store' target='_blank' className="custom-a">
            <div id='download-button-app'>
              <span>Download Now</span>
            </div>
          </a>
        </div> 
      </div>  
      {/*
      <div className='wrapper'>        
        <div id="intro">
          <p id="title">INTRODUCING</p>
          <p id="name">ArCurrency</p>
          <p id="desc">Fast way to help you exchange your money. Travel with ArCurrency, you could be able to use your camera to exchange your money in real-time</p>          
          <a href='https://link.n-o.me/arcurrency' target='_blank'>Download Now</a>
        </div>      
        <img className="img-1" src={arCurrency}/>
        <img className="img-2" src={arCurrency1}/>
      </div>    
      */}
      <div className='wrapper'>
        <img id="contact-logo" src={logo} alt="logo" />
        <div id="contact">
          <div id="contact-us-wrapper">
            <p id="title">CONTACT US</p>
            <p id="subtitle">WE WILL RESPONSE WITHIN 24 HOURS.</p>
            <input placeholder='NAME' type='text' onChange={handleChangeName} value={nameInput} />
            <input placeholder='EMAIL' type='email' onChange={handleChangeEmail} value={emailInput} />
            <textarea placeholder='MESSAGE' onChange={handleChangeMsg} value={msgInput} />            
            <button onClick={submitContact} className="custom-a" id="submit">
              <div id='download-button' className='submit-button' style={{ marginTop: '0px' }}>
                <span>{submitText}</span>
              </div>
            </button>
          </div>        
        </div>        
      </div> 
    </main>
  );
}

export default Index;
